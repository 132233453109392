exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-handrevalidatie-js": () => import("./../../../src/pages/handrevalidatie.js" /* webpackChunkName: "component---src-pages-handrevalidatie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kine-bovenste-lidmaten-js": () => import("./../../../src/pages/kine-bovenste-lidmaten.js" /* webpackChunkName: "component---src-pages-kine-bovenste-lidmaten-js" */),
  "component---src-pages-littekentherapie-js": () => import("./../../../src/pages/littekentherapie.js" /* webpackChunkName: "component---src-pages-littekentherapie-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

